<!--
 * @Author: your name
 * @Date: 2020-11-09 10:04:53
 * @LastEditTime: 2021-07-06 13:49:18
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/views/index/DownloadChildDown.vue
-->
<template>
  <div>
    <div class="t-p">
      <el-popover
        width="420"
        placement="right"
        hide-icon
        v-model="showMeetingModal"
      >
          <div class="color-impontant font-size-root">
          <div>请选择所需导出的时间</div>
          <div class="lay-box">已选时间:{{startDate}} - {{endDate}}</div>
          </div>
        <el-date-picker
          size='small'
          class="picker-rd"
          :value="[startDate,endDate]"
          :disabled='isExport'
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          >
        </el-date-picker>
        <el-row type="flex" justify="end" class="lay-box">
          <el-button size="small" @click="showMeetingModal = false">取消</el-button>
          <el-button type="primary" size="small" @click="onExportData" :loading='isExport'>确定</el-button>
        </el-row>
        <!-- <el-button type="primary" slot="reference">导出科会数据</el-button> -->
      </el-popover>
    </div>
    <div class="lay-box" v-loading='loading'>
      <el-table
        :data = 'downLoadFileList'
        stripe
      >
      <el-table-column
        prop='createdAt'
        align='center'
        label='日期'
      >
      </el-table-column>
      <el-table-column
        prop='title'
        align='center'
        label='报表名称'
      >
      </el-table-column>
      <el-table-column
        prop='location'
        align='center'
        label='描述'
      >
      </el-table-column>
      <el-table-column
        prop='isExpire'
        align='center'
        label='状态'
      >
      <template slot-scope="scope">
        <span v-if='scope.row.isExpire'>已过期</span>
        <span v-else-if='scope.row.status === 4' class="color-red">导出失败</span>
        <el-link v-else-if='scope.row.status === 1' type='primary' :underline="false" @click="onLoad">正在生成报告，刷新查看</el-link>
        <el-link v-else  type='primary' :underline="false" @click="onDownLoad(scope.row)">下载</el-link>
      </template>
      </el-table-column>
      </el-table>
      <el-row type='flex' justify="end" class="t-p">
      <el-pagination
        background
        hide-on-single-page
        layout="prev, pager, next"
        :page-size='pageSize'
        :current-page="pageNo"
        @current-change='onChange'
        :total='totalCount'
      ></el-pagination>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters} from 'vuex'
import { saveAs } from 'file-saver';
export default {
  name: 'DownLoadFile',
  data () {
    return {
      startDate: `${this.$moment().add(-30,'days').format('YYYY-MM-DD')} 00:00:00`,
	    endDate: `${this.$moment().format('YYYY-MM-DD')} 23:59:59`,
	    showMeetingModal:false,
	    isExport: false,
	    loading: false,
	    pageNo: 1,
	    pageSize: 20,
	    totalCount: 0,
	    pickerOptions:{
	      onPick: ({maxDate,minDate}) => {
          this.startDate = `${this.$moment(minDate).format('YYYY-MM-DD')} 00:00:00`
          this.endDate = `${this.$moment(maxDate).format('YYYY-MM-DD')} 23:59:59`
        },
        disabledDate: (current) => {
          const tooLate = this.startDate && this.$moment(current).diff(this.$moment(this.startDate), 'days') > 30;
      		const tooEarly = this.endDate && this.$moment(this.endDate).diff(this.$moment(current), 'days') > 30;
      		const currentMax = current > this.$moment().add(1,'days')
          return currentMax || tooLate || tooEarly
        },
	      shortcuts:[
	        {
	          text: '今天',
	          onClick: (picker) => {
              picker.$emit('pick',[this.$moment(), this.$moment()])
            }
	        },
	        {
	          text: '昨天',
	          onClick: (picker) => {
              picker.$emit('pick',[this.$moment().add(-1,'days'), this.$moment().add(-1,'days')])
            }
	        },
	        {
	          text: '过去7天',
	          onClick: (picker) =>{
              picker.$emit('pick',[this.$moment().add(-8,'days'), this.$moment().add(-1,'days')])
            }
	        },
	        {
	          text: '过去30天',
	          onClick: (picker) => {
              picker.$emit('pick',[this.$moment().add(-31,'days'), this.$moment().add(-1,'days')])
            }
	        }
	      ]
	    },
	    showExportBtn: false
    }
  },
  computed: {
    ...mapGetters(['downLoadFileList'])
  },
  methods: {
    ...mapActions(['getDownLoadFileList','exportMeetingData']),
    onChangePicker (e) {
      // this.
    },
    onChange (pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    onExportData(){
		if(this.isExport) return
    this.isExport = true
		let data = {
			beginAt:this.startDate,
			endAt:this.endDate,
			sceneIds:[]
		}
		this.exportMeetingData(data).then(res=>{
		  this.showMeetingModal = false
		  this.isExport = false
		  this.init()
		},rea=>{
		  this.$message.error(rea)
		  this.isExport = false
		})
	},
    init () {
      this.loading = true
      this.getDownLoadFileList(this.pageNo).then(res=> {
        this.loading = false
        this.totalCount = res.totalCount
      }, rea => {
        this.loading = false
      })
    },
    onDownLoad (v) {
      let title = `${v.location}-${v.title}`
      saveAs(v.url, title.replace(/\./g, "_"))
    },
    onLoad () {

    }
  },
  mounted () {
    this.init()
    // this.startDate = `${this.$moment().add(-30,'days').format('YYYY-MM-DD')} 00:00:00`
    // this.endDate = `${this.$moment().format('YYYY-MM-DD')} 23:59:59`
  }
}
</script>
<style lang="scss" scoped>
  .lay-box {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .t-p {
    padding-top: 15px;
  }
  .picker-rd {
    width: 100%;
  }
</style>
