<template>
  <page-main class="customize-page-main">
    <el-tabs v-model="activeTab">
      <el-tab-pane
        v-for="item in tabList"
        :key="item.key"
        :label="item.name"
        :name="item.key"
      />
    </el-tabs>
    <DownLoadFile v-if="activeTab === 'download'" />
  </page-main>
</template>
<script>
import DownLoadFile from '@/views/download/DownloadChildDown'
export default {
  name: 'DownloadCenter',
  components: {
    DownLoadFile
  },
  data() {
    return {
      activeTab: 'download', // message or download
      tabList: [{ name: '下载中心', key: 'download' }]
    }
  },
  methods: {
    onJump() {
      window.open('https://d.quxueshu.com')
    }
  }
}
</script>
